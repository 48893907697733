@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply font-light text-slate-500;
		font-family: 'Lexend Deca', sans-serif;
	}

	#root {
		@apply flex h-screen w-screen overflow-hidden bg-slate-100 max-lg:flex-col;
	}
}

.tox .tox-statusbar__right-container {
	display: none !important;
}
